<template>
  <div>
    <c-navbar v-if="!hideNavbar">
      <template v-slot:title>
        {{ $t('Characteristics') }}
      </template>
      <template v-slot:buttons>
        <b-button variant="primary" class="mr-2">
          {{ $t('Add characteristic') }}
        </b-button>
        <b-button variant="outline-primary" class="mr-2">{{ $t('Activate sorting') }}</b-button>
      </template>
    </c-navbar>
  </div>
</template>

<script>
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import {BButton} from "bootstrap-vue";

export default {
  name: "CharacteristicsList",
  components: {
    BButton,
    CNavbar
  },
  props: {
    hideNavbar: {
      type: Boolean,
      default: false
    }
  }
}
</script>
